
const localStorageKey = '__token__'

function handleUserResponse(response) {

  if (!response) return null
  if (!response.token) return response
  localStorage.setItem(localStorageKey, response.token)

  //set perfil por defeito
  if (response.user) {
    if (response.user.perfis) {
      if (response.user.perfis.length > 0) {
        localStorage.setItem('__perfil__', response.user.perfis[0].PERFIL)
        return { user: { ...response.user, profile: response.user.perfis[0].PERFIL } }
      } else {
        return { user: { ...response.user, profile: null } }
      }
    }
  }

}

const fetcher = async (url, method, params) => {

  try {

    const token = localStorage.getItem('__token__')
    let headers = { 'content-type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: token }
    }

    const response = await fetch('/service/api/' + url,
      {
        method: method,
        headers: headers,
        body: JSON.stringify(params)
      })
    const json = await response.json()
    return json

  }
  catch (error) { console.log(error) }
}

function getUser() {

  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }
  const profile = getProfile()
  const AuUser = getAuUser()
  return fetcher('user', 'GET')
    .then(response => {
      if (!response) return Promise.reject(null)
      if (response.error) return Promise.reject(null)
      if (response.success === false) return Promise.reject(null)


      //user dont have selected profile before anda now have roles attached to select
      if (response.user.perfis && !profile) {
        if (response.user.perfis.length > 0) {
          localStorage.setItem('__perfil__', response.user.perfis[0].PERFIL)
          return { ...response.user, profile: response.user.perfis[0].PERFIL, AuUser }
        } else {
          return { ...response.user, profile: null, AuUser } 
        }
      }

      //user have selected desired profile before
      return { ...response.user, profile, AuUser }

    })
    .catch(error => {
      performlogout()
      return Promise.reject(error)
    })

}


function performLoginGov(params) {
  return fetcher('login-gov', 'POST', params)
    .then(response => {
      if (!response) return Promise.reject(null)
      return response
    })
    .then(handleUserResponse)
    .catch(() => {
      return Promise.reject(null)
    })
}

function performLogin(params) {
  return fetcher('login', 'POST', params)
    .then(response => {
      if (!response) return Promise.reject(null)
      return response
    })
    .then(handleUserResponse)
    .catch(() => {
      return Promise.reject(null)
    })
}

function performLoginAutarquia(params) {
  return fetcher('autarquia/login', 'POST', params)
    .then(response => {
      if (!response) return Promise.reject(null)
      return response
    })
    .then((res) => {
      if(!res.success) return res
      localStorage.setItem('__AuUser__', JSON.stringify(res.user))
      return { AuUser: res.user}
    })
    .catch(() => {
      return Promise.reject(null)
    })
}

function performReset(params) {
  return fetcher('reset', 'POST', params)
    .then(response => {
      // if (!response) return Promise.reject(null)
      return response
    })
    // .then(handleUserResponse)
    .catch(() => {
      return Promise.reject(null)
    })
}

function performSetNewPassword(params) {
  return fetcher('recover', 'POST', params)
    .then(response => {
      // if (!response) return Promise.reject(null)
      return response
    })
    // .then(handleUserResponse)
    .catch(() => {
      return Promise.reject(null)
    })
}

function performEditPassword(params) {
  return fetcher('edit/password', 'POST', params)
    .then(response => {
      return response
    })
    .catch(() => {
      return Promise.reject(null)
    })
}

function performRegister(params) {
  return fetcher('register', 'POST', params)
    .then(response => {
      if (!response) return Promise.reject(null)
      return response
    })
    .then(handleUserResponse)
    .catch(() => {
      return Promise.reject(null)
    })
}

function performlogout() {
  localStorage.removeItem('localStorageKey');
  localStorage.removeItem('__perfil__');
  localStorage.removeItem('__AuUser__');
  localStorage.removeItem('__user_selected_id__');
  localStorage.removeItem('microAbreuUser');
  localStorage.removeItem('unicardUser');

  return fetcher('logout', 'POST')
    .then(response => {
      localStorage.removeItem('__token__'); 
    })
    .catch(error => {
      console.error(error);
    })
    .finally(() => {
      return Promise.resolve();
    });
}

function getToken() {
  return localStorage.getItem(localStorageKey)
}

function getProfile() {
  return localStorage.getItem('__perfil__')
}

function getAuUser() {
  return JSON.parse(localStorage.getItem('__AuUser__'))
}

export { performLogin,performLoginAutarquia, performReset, performSetNewPassword, performEditPassword, performRegister, performlogout, getToken, getUser, performLoginGov }